<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div>
			<a-tabs size="large" :default-active-key="1" v-model="activeTab">
				<div slot="tabBarExtraContent" class="dF aC" style="gap: 20px">
					<div v-if="activeTab === 1 && corporateInstance" class="dF aC" style="gap: 5px">
						<span>Projects:</span>
						<SelectProjects class="ml-2" />
					</div>
					<div class="dF aC" style="gap: 5px" v-if="activeTab === 1">
						<span>Print:</span>
						<a @click.prevent="downloadPDF" href="javascript:;" class="text-primary">
							Charts
						</a>
						<PDFOrientation class="ml-2" />
					</div>
					<div v-if="activeTab === 2">
						<a-input placeholder="Search here..." v-model="search"></a-input>
					</div>
					<div class="dF aC" style="gap: 5px">
						<span>Download:</span>
						<a @click.prevent="getCSV" href="javascript:;" class="text-primary">CSV</a>
					</div>
					<DatePicker @current-times-update="fetchChartData($event, 'currentData')"
						:disabled-compare-date="true" :setDefaultAsAllTime="setDefaultAsAllTime" />
					<div v-if="activeTab === 2" class="dF aC text-primary">
						<a-select v-model="selectedType" style="width: 150px" placeholder="Select Date"
							class="text-primary" :showArrow="true" :dropdownMenuStyle="{
								'text-align': 'center',
								'overflow-y': 'hidden',
								'max-height': 'max-content',
							}">
							<a-icon slot="suffixIcon" type="caret-down" class="text-primary" />

							<a-select-option v-for="t in typeList" :key="t" :value="t" class="text-primary">
								{{ t }}
							</a-select-option>
						</a-select>
					</div>
				</div>
				<a-tab-pane :key="1" tab="Overview" style="gap: 20px">
					<vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false"
						:preview-modal="false" pdf-content-width="100%" :manual-pagination="true" :html-to-pdf-options="{
							margin: pdfOrientation === 'portrait' ? [50.2, 10, 50.2, 10] : [10, 50, 5, 50],
							image: { type: 'jpeg', quality: 2 },
							enableLinks: true,
							html2canvas: { scale: 1, useCORS: true },
							jsPDF: {
								orientation: pdfOrientation
							}
						}" @beforeDownload="beforeDownload($event)" ref="html2Pdf">
						<section slot="pdf-content">
							<div v-if="!error" class="report-grid">
								<ChartCard class="html2pdf__page-break" :details="inventoryByStatus"
									:loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="inventoryByProductType"
									:loading="loading" />
							</div>
							<div v-if="!error" class="report-grid report-grid-1">
								<ChartCard :details="inventoryByModelElevation" :loading="loading" />
							</div>
							<div v-else class="mt-10" style="text-align: center">
								<h4>Something went wrong. Please Try again!</h4>
								<a-button type="primary" @click="fetchChartData">Retry</a-button>
							</div>
						</section>
					</vue-html2pdf>
				</a-tab-pane>
				<a-tab-pane :key="2" tab="Pricing History">
					<div v-if="selectedType === 'By Add On'" class="dF aC mb-3">
						<a-button :type="addonTab === 'parking' ? 'primary' : ''" @click="addonTab = 'parking'">Parking
							Spots</a-button>
						<a-button class="ml-4" :type="addonTab === 'lockers' ? 'primary' : ''"
							@click="addonTab = 'lockers'">Lockers</a-button>
						<a-button class="ml-4" :type="addonTab === 'bikeRacks' ? 'primary' : ''"
							@click="addonTab = 'bikeRacks'">Bike Racks</a-button>
					</div>
					<a-card class="table-card">
						<a-table class="tableStyle" :columns="columns" :data-source="filterRecords"
							:row-key="(record) => record.key" @change="handleTableChange" :loading="loading">
							<template #conditionPremiums="conditionPremiums">
								<div v-for="(cp, i) in conditionPremiums" :key="i">
									{{ cp }} &nbsp;
								</div>
							</template>
							<template #users="users">
								<div v-for="(u, i) in users" :key="i">
									{{ u.name }} &nbsp;
								</div>
							</template>
							<template #dates="dates">
								<div v-for="(date, i) in dates" :key="i">
									{{ $formatDate(date) }} &nbsp;
								</div>
							</template>
							<template #percentageChanges="percentageChanges">
								<span v-for="(p, i) in percentageChanges" :key="i">
									<div :class="p > 0 ? 'text-success' : p < 0 ? 'text-danger' : 'text-gray'">
										{{ $formatNumber(p) }} %
									</div>
								</span>
							</template>

							<template #priceChanges="priceChanges">
								<span v-for="(p, i) in priceChanges" :key="i">
									<div :class="p > 0 ? 'text-success' : p < 0 ? 'text-danger' : 'text-gray'">
										${{ $formatNumber(p) }}
									</div>
								</span>
							</template>
							<template #totalPriceChanges="totalPriceChanges">
								<div v-for="(tp, i) in totalPriceChanges" :key="i">
									${{ $formatNumber(tp) }}
								</div>
							</template>
						</a-table>
					</a-card>
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script>
import ChartCard from "@/components/charts/chartCard";
import chartData from "./chartData";
import DatePicker from "@/components/charts/datePicker.vue";
import PDFOrientation from "@/components/charts/pdfOrientation.vue";
import SelectProjects from "@/components/charts/selectProjects.vue";
import { mapState, mapMutations } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import { formatDate } from 'bh-mod'

export default {
	components: {
		ChartCard,
		DatePicker,
		VueHtml2pdf,
		PDFOrientation,
		SelectProjects
	},
	data: () => ({
		loading: false,
		error: false,
		setDefaultAsAllTime: false,
		activeTab: 1,
		dataRecords: [],
		sortedInfo: {},
		selectedType: "",
		inventoryReport: {
			currentData: {},
			compareData: {},
		},
		addonTab: "parking",
		search: "",
		tabLinking: {
			'lots': 'By Lot',
			'elevation': 'By Model/Elevation',
			'units': 'By Unit',
			'premiums': 'By Premium',
			'addOns': 'By Add On',
		}
	}),
	computed: {
		...mapState([
			"currentStartDate",
			"currentEndDate",
			"compareStartDate",
			"compareEndDate",
			"records",
			"instance",
		]),

		pdfOrientation() {
			return this.$store.state.pdfOrientation
		},

		corporateInstance() {
			return (this.instance && this.instance.projectType === 'corporate' && this.instance.children.length)
		},

		chartTitles() {
			if (this.productType === "lowrise") {
				return [
					'Lot inventory by status',
					'Lot inventory by product type',
					'Standing inventory value by model/elevation',
				]
			} else {
				return [
					'Inventory by unit status',
					'Inventory by unit type',
					'Inventory value by unit type'
				]
			}
		},

		typeList() {
			if (this.productType === "lowrise") {
				return ["By Lot", "By Model/Elevation"];
			} else {
				return ["By Unit", "By Premium", "By Add On"];
			}
		},

		productType() {
			return this.instance.productType;
		},

		inventoryByStatus() {
			const details = chartData.inventoryByStatus();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
				delete details.option.colorBy
				delete details.option.color
			}
			if (this.productType === "lowrise") {
				return {
					...details,
					chartDetails: this.getChartDetails("lotInventoryByStatus"),
				};
			}
			details.option.yAxis.name = "UNIT COUNTS"
			if (details.option.color) {
				details.option.color = details.option.color.filter(c => c !== '#3395c6')
			}
			return {
				...details,
				title: "Inventory by unit status",
				chartDetails: this.getChartDetails("inventoryByUnitStatus"),
			};
		},

		inventoryByProductType() {
			const details = chartData.inventoryByProductType();
			if (this.productType === "lowrise") {
				return {
					...details,
					chartDetails: this.getChartDetails(
						"lotInventoryByProductType"
					),
				};
			}
			details.option.yAxis.name = "UNIT COUNTS"
			if (details.itemByColor && details.itemByColor["Standing Inventory"]) {
				delete details.itemByColor["Standing Inventory"];
			}
			return {
				...details,
				title: "Inventory by unit type",
				chartDetails: this.getChartDetails("inventoryByUnitType"),
			};
		},

		inventoryByModelElevation() {
			const details = chartData.inventoryByModelElevation();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			if (this.productType === "lowrise") {
				return {
					...details,
					chartDetails: this.getChartDetails("inventoryValueByModel"),
				};
			}
			details.option.yAxis.name = "SUM OF UNIT INVENTORY VALUE"
			return {
				...details,
				title: "Inventory value by unit type",
				chartDetails: this.getChartDetails("inventoryValueByUnit"),
			};
		},

		columns() {
			return this.getColumns();
		},

		filename() {
			return `Inventory Report(${this.$formatDate(this.currentStartDate)} - ${this.$formatDate(this.currentEndDate)})`;
		},

		filterRecords() {
			if (this.search) {
				return this.dataRecords.filter((r) => {
					let search = this.search?.toLowerCase();
					if (this.selectedType === 'By Lot') {
						return r.lot?.toLowerCase().includes(search);
					} else if (this.selectedType === 'By Model/Elevation') {
						return r.modelElevation?.toLowerCase().includes(search) || r.modelType?.toLowerCase().includes(search) || r.frontage.toString().includes(search);
					} else if (this.selectedType === 'By Unit') {
						return r.unitName?.toLowerCase().includes(search) || r.unitType?.toLowerCase().includes(search) || r.unit.toString().includes(search);
					} else if (this.selectedType === 'By Premium') {
						return r.name?.toLowerCase().includes(search);
					} else if (this.selectedType === 'By Add On') {
						return r.name.toString()?.toLowerCase().includes(search);
					}
				});
			}
			return this.dataRecords.map((r, index) => {
				return {
					key: r.id + index,
					...r,
				}
			});
		}
	},

	watch: {
		selectedType() {
			this.search = "";
		},
	},

	created() {
		this.selectedType =
			this.productType === "lowrise" ? "By Lot" : "By Unit";

		if (this.$route.query.tab) {
			this.selectedType = this.tabLinking[this.$route.query.tab] || this.selectedType;
			this.activeTab = 2;
			// set default as all time if route is coming from IPM
			this.setDefaultAsAllTime = true;
			if(this.$route.query.search) {
				this.$nextTick(() => {
					this.search = this.$route.query.search;
				});
			}
		}
	},

	methods: {
		...mapMutations(["setCurrentDates", "setCompareDates", "setRecords"]),
		formatDate,

		getChartDetails(chartType) {
			const chartDetails = [];
			if (this.inventoryReport.currentData[chartType]) {
				chartDetails.push({
					startDate: this.currentStartDate,
					endDate: this.currentEndDate,
					data: this.inventoryReport.currentData[chartType],
				});
			}

			if (this.inventoryReport.compareData[chartType]) {
				chartDetails.push({
					startDate: this.compareStartDate,
					endDate: this.compareEndDate,
					data: this.inventoryReport.compareData[chartType],
				});
			}
			return chartDetails;
		},

		fetchChartData(
			{
				startDate = this.currentStartDate,
				endDate = this.currentEndDate,
			},
			dateType = "currentData"
		) {
			this.error = false;
			this.loading = true;

			if (dateType === "currentData") {
				this.setCurrentDates({ startDate, endDate });
			} else {
				this.setCompareDates({ startDate, endDate });
			}

			if (!startDate && !endDate) {
				this.inventoryReport[dateType] = {};
				this.loading = false;
				this.$forceUpdate();
				return;
			}

			this.$api
				.post(`/daily-reports/${this.$store.state.instance.id}/graph`, {
					type: "inventory",
					start: startDate,
					end: endDate,
					pType: this.productType,
				})
				.then(({ data }) => {
					this.setRecords({
						records: data.records,
						type: "inventory",
					});
					delete data.records;
					this.inventoryReport[dateType] = data;
					this.loading = false;
				})
				.catch((error) => {
					console.error(error);
					this.error = true;
					this.loading = false;
				});
		},

		getColumns() {
			let { sortedInfo } = this;
			sortedInfo = sortedInfo || {};
			const columns = [];
			if (this.selectedType === "By Lot") {
				this.dataRecords = this.records.inventory.priceHistory.lots;
				columns.push(
					...[
						{
							title: "Lot #",
							dataIndex: "lot",
							key: "lot",
							sorter: (a, b) => parseInt(a.lot?.replace('Lot', '')) - parseInt(b.lot?.replace('Lot', '')),
						},
						{
							title: "Condition/Premium",
							dataIndex: "conditionPremiums",
							key: "conditionPremiums",
							scopedSlots: { customRender: "conditionPremiums" },
						},
					]
				);
			} else if (this.selectedType === "By Model/Elevation") {
				this.dataRecords =
					this.records.inventory.priceHistory.elevation;
				columns.push(
					...[
						{
							title: "Model/Elevation",
							dataIndex: "modelElevation",
							key: "modelElevation",
							sorter: (a, b) => a.modelElevation.localeCompare(b.modelElevation),
						},
						{
							title: "Model Type",
							dataIndex: "modelType",
							key: "modelType",
							sorter: (a, b) => a.modelType.localeCompare(b.modelType),
						},
						{
							title: "Frontage",
							dataIndex: "frontage",
							key: "frontage",
							sorter: (a, b) => a.frontage - b.frontage,
						},
						{
							title: "Package",
							dataIndex: "packageName",
							key: "packageName",
							sorter: (a, b) => a.packageName.localeCompare(b.packageName),
						},
					]
				);
			} else if (this.selectedType === "By Unit") {
				this.dataRecords = this.records.inventory.priceHistory.units;
				columns.push(
					...[
						{
							title: "Floor",
							dataIndex: "floor",
							key: "floor",
							sorter: (a, b) => a.floor.localeCompare(b.floor),
						},
						{
							title: "Unit #",
							dataIndex: "unit",
							key: "unit",
							sorter: (a, b) => Number(a.unit) - Number(b.unit),
						},
						{
							title: "Unit Name",
							dataIndex: "unitName",
							key: "unitName",
							sorter: (a, b) => a.unitName.localeCompare(b.unitName),
						},
						{
							title: "Unit Code",
							dataIndex: "unitCode",
							key: "unitCode",
							sorter: (a, b) => a.unitCode.localeCompare(b.unitCode),
						},
					]
				);
			} else if (this.selectedType === "By Premium") {
				this.dataRecords = this.records.inventory.priceHistory.premiums;
				columns.push(
					...[
						{
							title: "Premium",
							dataIndex: "name",
							key: "name",
						},
					]
				);
			} else if (this.selectedType === "By Add On") {
				this.dataRecords = this.records.inventory.priceHistory.addOns?.[this.addonTab] || [];
				columns.push(
					...[
						{
							title: "Add On #",
							dataIndex: "name",
							key: "name",
							sorter: (a, b) => a.name.localeCompare(b.name),
						},
					]
				);
			}

			columns.push(
				...[
					{
						title: "Updated By",
						dataIndex: "users",
						key: "users",
						scopedSlots: { customRender: "users" },
					},
					{
						title: "Date",
						dataIndex: "dates",
						key: "dates",
						scopedSlots: { customRender: "dates" },
					},
					{
						title: "Percentage",
						dataIndex: "percentageChanges",
						key: "percentageChanges",
						scopedSlots: { customRender: "percentageChanges" },
					},
					{
						title: "Price change",
						dataIndex: "priceChanges",
						key: "priceChanges",
						scopedSlots: { customRender: "priceChanges" },
					},
					{
						title: "Total Price",
						dataIndex: "totalPriceChanges",
						key: "totalPriceChanges",
						scopedSlots: { customRender: "totalPriceChanges" },
					},
				]
			);
			return columns;
		},

		handleTableChange(pagination, filters, sorter) {
			this.sortedInfo = sorter;
		},

		downloadPDF() {
			this.$nprogress.start();
			this.$refs.html2Pdf.generatePdf();
		},

		async beforeDownload({ html2pdf, options, pdfContent }) {
			options.filename = this.filename;
			await html2pdf()
				.set(options)
				.from(pdfContent)
				.toPdf()
				.get("pdf")
				.then((pdf) => {
					const totalPages = pdf.internal.getNumberOfPages();
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(11);
						pdf.text(
							"Page " + i,
							pdf.internal.pageSize.getWidth() * 0.88,
							10
						);

						pdf.setFontSize(16);

						const title = this.chartTitles[i - 1] || ''
						pdf.text(
							title,
							(pdf.internal.pageSize.getWidth() * 0.44) - title.length,
							(this.pdfOrientation === 'portrait' ? 25 : 10)
						);
					}
				})
				.save()
				.then(() => {
					this.$nprogress.done();
				})
				.catch(() => {
					this.$nprogress.done();
				});
		},

		getCSV() {
			if (this.productType === "lowrise") {
				if (this.activeTab === 1) {
					this.createCSV(this.records.inventory.lots.map((l) => {
						return {
							...l,
							closingDate: l.closingDate && formatDate(l.closingDate) || "",
						}
					}));
				} else {
					const priceHistoryType = this.selectedType === "By Lot" ? "lots" : "elevation";
					this.createCSV(
						this.records.inventory.priceHistory[priceHistoryType].map((ph) => {
							let dates = (ph.dates || []).map(d => d && formatDate(d) || '')
							let users = (ph.users || []).map(u => u.name || '')
							return {
								...ph,
								dates: dates || "",
								users: users || "",
							}
						}),
						`Inventory Price History by ${priceHistoryType}(${this.$formatDate(this.currentStartDate)} - ${this.$formatDate(this.currentEndDate)})`
					);
				}
			} else {
				if (this.activeTab === 1) {
					this.createCSV(this.records.inventory.units);
				} else {
					const priceHistoryType = this.selectedType === "By Unit" ? "units" : this.selectedType === "By Premium" ? "premiums" : "addOns";
					let records = this.records.inventory.priceHistory[priceHistoryType];
					if (this.selectedType === 'By Add On') {
						records = records[this.addonTab] || []
					}
					this.createCSV(
						records.map((ph) => {
							let dates = (ph.dates || []).map(d => d && formatDate(d) || '')
							let users = (ph.users || []).map(u => u.name || '')
							return {
								...ph,
								dates: dates || "",
								users: users || "",
							}
						}),
						`Inventory Price History by ${priceHistoryType}(${this.$formatDate(this.currentStartDate)} - ${this.$formatDate(this.currentEndDate)})`
					);
				}
			}
		},

		createCSV(records, filename = this.filename) {
			if (records.length) {
				let csv = Object.keys(records[0]).map(r => `"${r}"`).join(",");
				csv += "\n";
				records.forEach((row) => {
					csv += Object.values(row)
						.map((r) => {
							if (!r) {
								return "";
							}
							if (typeof rp === 'number') {
								return this.$formatNumber(rp);
							}
							if (typeof r === "string") {
								return r.replaceAll(", ", " & ");
							}
							if (typeof r === "object") {
								if (r.premiums && r.premiums.length) {
									return r.premiums.join(" & ");
								}
								if (r.addOns && r.addOns.length) {
									return r.addOns.join(" & ");
								}
								if (r.length) {
									return r
										.map((rp) => {
											if (typeof rp === "string") {
												return rp.replaceAll(", ", " & ");
											}
											if (typeof rp === 'number') {
												return this.$formatNumber(rp);
											}
											return rp;
										})
										.join(" => ");
								}
								return "";
							}
							return r;
						})
						.map(r => `"${r}"`)
						.join(",");
					csv += "\n";
				});

				const anchor = document.createElement("a");
				anchor.href =
					"data:text/csv;charset=utf-8," + encodeURIComponent(csv);
				anchor.target = "_blank";
				anchor.download = `${filename}.csv`;
				anchor.click();
			} else {
				this.$message.info("No records found!");
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.report-grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 20px;

	@media screen and (max-width: 1279px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

.report-grid-1 {
	padding-top: 20px;
	grid-template-columns: auto;

	@media screen and (min-width: 768px) {
		grid-template-columns: auto;
	}
}

.ant-select-selection__placeholder {
	color: var(--primary);
}
</style>
